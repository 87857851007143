// extracted by mini-css-extract-plugin
export var mobileAppCardTechStack = "dx_Dm";
export var mobileAppCardTechStack__card = "dx_Dn";
export var mobileAppCardTechStack__description = "dx_Dq";
export var mobileAppCardTechStack__iconsMobileDev = "dx_Dr";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechJS = "dx_Dw";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechKotlin = "dx_Dt";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechReact = "dx_Dv";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechSwift = "dx_Ds";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechTS = "dx_Dx";
export var mobileAppCardTechStack__title = "dx_Dp";