// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "H_f4";
export var devBenefitBussinesMobileDev = "H_f1";
export var domainExpertiseMobileDev = "H_fX";
export var mobileAppDevTechnologies = "H_fT";
export var mobileAppDevTechnologies__wrapper = "H_fV";
export var ourKeyPrinciplesMobileDev = "H_f0";
export var serviceMobileTestimonialPost = "H_fZ";
export var servicesCardBorderIconMobileDevSection = "H_fR";
export var servicesCardWithTopBorderSectionMobileApp = "H_fS";
export var servicesProcessMobileDevSection = "H_fY";
export var successStoriesMobileSection = "H_f3";
export var weBuildProjectsDifferentSection = "H_fW";
export var whyChooseServicesMobile = "H_f2";